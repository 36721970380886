@use '../../../../../core/presentation/constants/dimens' as *;

$listPageHeaderBackgroundColor: #ffffff;

.root {
  height: $rootPageLeftMenuHeaderHeight;
  z-index: 4;
  top: 0;
  background-color: $listPageHeaderBackgroundColor;
  width: calc(100% - $rootPageLeftMenuWidth);
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 20px;
  gap: 15px;
}

.header {
  flex-grow: 1;
}

.search {
  width: 30%;
}

.filter {
  border-radius: 5px;
  border: 1px solid transparent;
}
