.root {
  padding: 8px;
  cursor: pointer;
  display: flex;
  min-height: 42px;
  align-items: center;
  justify-content: center;
}

.textButton {
  padding: 8px 20px;
}

a.root {
  text-decoration: none;
}

.root:disabled,
.root.disabled {
  pointer-events: none;
}
