@use "../typography/bodies.module.scss" as *;
@use "../palette/bodies.module.scss" as *;

.body1.link {
  color: $body1Link;
  text-decoration: none;
}

.body1.link:hover {
  text-decoration: underline;
}

.body2.primary {
  color: $body2Primary;
}

.body3.error {
  color: $body3Error;
}

.body4.secondary {
  color: $body4Secondary;
}

.body5.secondary {
  color: $body5Secondary;
}

.body6.secondary {
  color: $body6Secondary;
}
