.root {
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: flex-start;
  justify-content: flex-start;
}

.label {
  display: block;
}

.valueContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.value {
  display: block;
}

.additionalValue {
  white-space: pre-wrap;
  display: block;
  margin-top: 3px;
}

