@use '../../../../../admin/core/presentation/constants/dimens' as *;

$listPageHeaderBackgroundColor: #ffffff;

.root {
  z-index: 4;
  top: 0;
  background-color: $listPageHeaderBackgroundColor;
  width: calc(100% - $rootPageLeftMenuWidth);
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 6px;
}

.wrapper {
  width: 100%;
  padding: 0 20px;
  display: flex;
}

.container {
  width: 100%;
  border-bottom: 1px solid #B8C1CC;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 10px 0;
}

.carrierName {
  margin: 0;
}

.segmentsContainer {
  display: block;
}

.subtitleContainer {
  margin-bottom: 4px;
}

.subtitle {
  margin: 0;
}
