@use "../../../../../core/presentation/constants/dimens" as *;

$leftMenuBackgroundColor: #F5F6F9;
$menuBorderColor: #E0E3E7;

.root {
  height: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  overflow-x: hidden;
  width: $rootPageLeftMenuWidth;
  background-color: $leftMenuBackgroundColor;
  display: flex;
  flex-direction: column;
}

.header {
  padding: 10px 20px;
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid $menuBorderColor;
}

.menu {
  padding: 10px 0;
  flex: 1 1 auto;
}

.menuItemsList {
  padding: 0;
  margin: 0;
  width: 100%;

  & li {
    list-style-type: none;
    display: flex;
    width: 100%;
  }
}

.menuItemLink {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  &.defaultCursor {
    cursor: default;
  }
}

.menuItemLink svg {
  display: block;
  margin-right: 4px;
}

.menuItemText {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.footer {
  border-top: 1px solid $menuBorderColor;
}
