.calendar {
  background-color: white;

  & table {
    width: 100%;
    border-collapse: collapse;

    & td,
    & th {
      text-align: center;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  padding-bottom: 5px;

  & .changeMonthButton {
    flex: 0 0 auto;
    width: 24px;
    height: 24px;
    background-size: 24px;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover {
      background-color: #dddddd;
    }
  }

  & .monthName {
    flex: 1 1 auto;
    text-align: center;
  }
}

.day,
.weekDay {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  min-height: 24px;
}

.weekDay {
  pointer-events: none;
  text-transform: lowercase;
}

.day {
  cursor: pointer;
  flex-direction: column;
  border: 1px solid transparent;

  &.range {
    background-color: #f3dfc6;
    border: 1px solid #f3dfc6;
    border-radius: 0;

    &.dateFrom {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &.dateTo {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  &.hidden {
    visibility: hidden;
  }

  &.dateFrom:not(.dateTo) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  &.dateTo:not(.dateFrom) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }

  & .dayName {
    text-align: center;
    width: 24px;
    height: 24px;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .dayDescription {
    color: #aaaaaa;
    font-size: 0.8em;
    min-height: 0.8em;
  }
}
