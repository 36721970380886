.root {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}

.actionsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.errorMessage {
  margin-left: 10px;
}
