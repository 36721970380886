@use "../palette/tables.module.scss" as *;
@use "../typography/tables.module.scss" as *;

.table thead th,
.table thead th:first-child,
.table thead th:last-child {
  border-color: $strokeTableRowDefault;
  background: $backgroundTableHeaderDefault;
}

.table tr td {
  background-color: $backgroundTableRowDefault;
}

.table tr:hover td {
  background-color: $backgroundTableRowHovered;
}

.table tr td,
.table tbody tr td,
.table tr td:first-child,
.table tr td:last-child {
  border-color: $strokeTableRowDefault;
}

.table th .tableHeader1.primary {
  color: $textTableHeader1Default;
}

.table th:hover .tableHeader1.primary {
  color: $textTableHeader1Hovered;
}

.table td .tableRow1.primary,
.table td a.tableRow1.primary {
  color: $textTableRow1PrimaryDefault;
}

.table td a .tableRow1.primary,
.table td a:hover .tableRow1.primary,
.table td a.tableRow1.primary,
.table td a.tableRow1.primary:hover {
  text-decoration: underline;
  text-decoration-color: $textTableRow1PrimaryDefault;
}

.table td .tableRow1.secondary {
  color: $textTableRow1SecondaryDefault;
}

.table td .tableRow2.primary {
  color: $textTableRow2PrimaryDefault;
}

.table td .tableRow2.secondary {
  color: $textTableRow2SecondaryDefault;
}

.table .errorMessageContainer {
  border-color: $strokeTableRowDefault;
}

.tableRow2.error {
  color: $textTableRow2Error;
}

.table .tableRow2.link {
  color: $textTableRow2LinkDefault;
}

.table .tableRow2.link {
  text-decoration: none;
}

.table .tableRow2.link:hover,
.table tr:hover .tableRow2.link {
  text-decoration: underline;
}

