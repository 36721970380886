@use '../../../../../core/presentation/constants/dimens' as *;

.root {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid transparent;
  padding: 15px $objectPageFormHorizontalPadding;
  height: $objectPageHeaderHeight;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  z-index: 4;
  position: fixed;
  top: $objectPageVerticalPadding;
  width: $objectPageContentWidth;
}

.title {
  margin: 0;
}
