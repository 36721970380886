$strokeTableRowDefault: #D9DBDE;

$backgroundTableHeaderDefault: linear-gradient(180deg, rgba(255, 255, 255, 0.27) 0%, rgba(255, 255, 255, 0.01) 100%), #F2F4F7;
$backgroundTableRowDefault: #FFFFFF;
$backgroundTableRowHovered: #FFF4EF;

$textTableHeader1Default: #253247;
$textTableHeader1Hovered: #E54F05;

$textTableRow1PrimaryDefault: #253247;
$textTableRow1SecondaryDefault: #9FAAB7;

$textTableRow2PrimaryDefault: #6D7885;
$textTableRow2SecondaryDefault: #6D7885;

$textTableRow2Error: #253247;

$textTableRow2LinkDefault: #E54F05;
