.root {
  overflow: auto;
  z-index: 6;
  border: 1px solid transparent;
  border-radius: 5px;
  margin: 5px 0;

  &.noScroll {
    position: absolute;
  }

  &.hasScroll {
    position: fixed;
  }
}
