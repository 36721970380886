@use "../typography/checkboxes.module.scss" as *;
@use "../palette/checkboxes.module.scss" as *;

$checkboxSide: 24px;

.checkboxLabel1.primary {
  color: $checkboxLabel1Primary;
}

.checkbox1 + label {
  min-height: $checkboxSide;
}

.checkbox1 + label > div {
  margin-left: $checkboxSide;
  min-height: $checkboxSide;
}

.checkbox1 + label::before {
  content: " ";
  position: absolute;

  width: $checkboxSide;
  height: $checkboxSide;
  background-image: url("../../resources/assets/icons/icCheckboxUnchecked.svg");
}

.checkbox1:checked + label::before {
  background: url("../../resources/assets/icons/icCheckboxChecked.svg");
}
