@use "./fonts.module.scss" as *;

.headline1 {
  font-family: $fontFamily;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headline2 {
  font-family: $fontFamily;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headline3 {
  font-family: $fontFamily;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.headline4 {
  font-family: $fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headline5 {
  font-family: $fontFamily;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.headline6 {
  font-family: $fontFamily;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.headline7 {
  font-family: $fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
