.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.inputWrapper {
  width: 100%;
}

.input {
  width: 100%;
}

