@use '../../../../../core/presentation/constants/dimens' as *;

.root {
  padding: 12px 20px;
  height: 100vh;
}

.content {
  top: $objectPageVerticalPadding;
  bottom: $objectPageVerticalPadding;
  width: $objectPageContentWidth;
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  position: fixed;
  overflow: auto;
  z-index: 5;
}

.withHeader {
  top: $objectPageVerticalPadding + $objectPageHeaderHeight;
}

.withFooter {
  bottom: $objectPageVerticalPadding + $objectPageFooterHeight;
}
