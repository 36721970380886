@use "../typography/inputs.module.scss" as *;
@use "../palette/inputs.module.scss" as *;
@use "../palette/cursors.module.scss" as *;

.input1.primary {
  border: 1px solid $strokeInput1PrimaryDefault;
  outline: 2px solid transparent;
  border-radius: 5px;
  background-color: $backgroundInput1PrimaryDefault;
  color: $textInput1PrimaryDefault;
  caret-color: $cursorPrimary;
}

.input1.primary.hasErrors {
  border-color: $strokeInput1PrimaryError;
}

.input1.primary:hover {
  border-color: $strokeInput1PrimaryHovered;
}

.input1.primary:focus,
.input1.primary:focus-visible {
  outline-color: $outlineInput1PrimaryFocused;
  border-color: $strokeInput1PrimaryPressed;
}

.input1.primary.active,
.input1.primary:active {
  border-color: $strokeInput1PrimaryPressed;
}

.input1.primary.placeholder,
.input1.primary::placeholder {
  color: $placeholderInput1PrimaryDefault;
}

.input1.primary::placeholder:disabled {
  color: $placeholderInput1PrimaryDisabled;
}

.input1.primary.disabled,
.input1.primary:disabled {
  color: $placeholderInput1PrimaryDisabled;
  background-color: $backgroundInput1PrimaryDisabled;
  border-color: $strokeInput1PrimaryDisabled;
}

.input1.primary:-webkit-autofill,
.input1.primary:-webkit-autofill:hover,
.input1.primary:-webkit-autofill:focus,
.input1.primary:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $backgroundInput1PrimaryDefault inset !important;
}

.input1.primary:-webkit-autofill:disabled {
  -webkit-box-shadow: 0 0 0 30px $backgroundInput1PrimaryDisabled inset !important;
}

.input1.primary.hasErrors {
  border-color: $strokeInput1PrimaryError !important;
}

.input2.primary {
  border: 1px solid $strokeInput1PrimaryDefault;
  outline: 2px solid transparent;
  border-radius: 5px;
  padding: 6px 8px;
  background-color: $backgroundInput1PrimaryDefault;
  color: $textInput1PrimaryDefault;
  caret-color: $cursorPrimary;
}

.input2.primary.hasErrors {
  border-color: $strokeInput1PrimaryError;
}

.input2.primary:hover {
  border-color: $strokeInput1PrimaryHovered;
}

.input2.primary:focus,
.input2.primary:focus-visible {
  outline-color: $outlineInput1PrimaryFocused;
  border-color: $strokeInput1PrimaryPressed;
}

.input2.primary.active,
.input2.primary:active {
  border-color: $strokeInput1PrimaryPressed;
}

.input2.primary.placeholder,
.input2.primary::placeholder {
  color: $placeholderInput1PrimaryDefault;
}

.input2.primary::placeholder:disabled {
  color: $placeholderInput1PrimaryDisabled;
}

.input2.primary.disabled,
.input2.primary:disabled {
  color: $placeholderInput1PrimaryDisabled;
  background-color: $backgroundInput1PrimaryDisabled;
  border-color: $strokeInput1PrimaryDisabled;
}

.input2.primary:-webkit-autofill,
.input2.primary:-webkit-autofill:hover,
.input2.primary:-webkit-autofill:focus,
.input2.primary:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $backgroundInput1PrimaryDefault inset !important;
}

.input2.primary:-webkit-autofill:disabled {
  -webkit-box-shadow: 0 0 0 30px $backgroundInput1PrimaryDisabled inset !important;
}

.input2.primary.hasErrors {
  border-color: $strokeInput1PrimaryError !important;
}

.searchIcon {
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendarIcon {
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
