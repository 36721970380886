@use "../typography/chips.module.scss" as *;
@use "../palette/chips.module.scss" as *;

.chip1.primary {
  background-color: $backgroundChip1PrimaryDefault;
  border-color: $strokeChip1PrimaryDefault;
  color: $textChip1PrimaryDefault;

  & .clear {
    width: 13px;
    height: 17px;
    background-image: url("../../resources/assets/icons/icCloseOutlineDefault.svg");
  }

  & .clear:hover {
    background-image: url("../../resources/assets/icons/icCloseOutlineHovered.svg");
  }
}

a.chip1.primary {
  text-decoration: none;
}

.chip1.primary:hover {
  border-color: $strokeChip1PrimaryHovered;
}
