@use '../../../../../core/presentation/constants/dimens' as *;

.content {
  margin-left: $rootPageLeftMenuWidth;
  height: 100%;
}

.page {
  min-height: 100%;
  position: relative;
}
