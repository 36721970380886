@use '../../../../../core/presentation/constants/dimens' as *;

.root {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid transparent;
  z-index: 4;
  position: fixed;
  bottom: $objectPageVerticalPadding;
  height: $objectPageFooterHeight;
  width: $objectPageContentWidth;
  display: flex;
  align-items: center;
  padding: 0 $objectPageFormHorizontalPadding;
}
