@use "./fonts.module.scss" as *;

.body1 {
  font-family: $fontFamily;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.08px;
}

.body2 {
  font-family: $fontFamily;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.body3 {
  font-family: $fontFamily;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
}

.body4 {
  font-family: $fontFamily;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  line-height: 18px;
}

.body5 {
  font-family: $fontFamily;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}

.body6 {
  font-family: $fontFamily;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
