.root {
  padding: 8px 12px;
  border: 1px solid transparent;
  border-radius: 5px;
}

.content {
  display: flex;
  gap: 4px;
  flex-direction: row;
}

.clear {
  cursor: pointer;
}
