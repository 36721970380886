@use "../typography/calendars.module.scss" as *;
@use "../palette/calendars.module.scss" as *;

.calendarItem1.primary {
  color: $textCalendarItem1PrimaryDefault;

  & .selected {
    color: $textCalendarItem1PrimarySelected;
    background-color: $backgroundCalendarItem1PrimarySelected;
  }
}

.calendarItem1.primary:hover {
  color: $textCalendarItem1PrimaryHovered;
}

.calendarItem1.secondary {
  color: $textCalendarItem1SecondaryDefault;
}

.calendarItem1.secondary:hover {
  color: $textCalendarItem1SecondaryHovered;
}

.calendarItem2.primary {
  color: $textCalendarItem1PrimaryDefault;

  & .selected {
    color: $textCalendarItem1PrimarySelected;
    background-color: $backgroundCalendarItem1PrimarySelected;
  }
}

.calendarItem2.primary:hover {
  color: $textCalendarItem1PrimaryHovered;
}

.calendarItem1.secondary {
  color: $textCalendarItem1SecondaryDefault;
}

.calendarItem1.secondary:hover {
  color: $textCalendarItem1SecondaryHovered;
}

.calendarItem2.secondary {
  color: $textCalendarItem2SecondaryDefault;
}

.calendarItem2.secondary:hover {
  color: $textCalendarItem2SecondaryHovered;
}
