$backgroundColor: #F5F6F9;
$formBackgroundColor: #FFFFFF;
$formBorderColor: #D9DBDE;

.root {
  background-color: $backgroundColor;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.formContainer {
  background-color: $formBackgroundColor;
  margin: 0 auto;
  border: 1px solid $formBorderColor;
  padding: 16px 20px 25px;
  border-radius: 10px;
  min-width: 389px;
  max-width: 500px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin: 12px 0 8px;
}

.formRow {
  width: 100%;
  margin: 8px 0;
  display: flex;
  flex-direction: column;
}

.submitRow {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.errorMessage {
  margin-top: 5px;
}

.successMessage {
  margin-top: 5px;
}

.backButton {
  margin-top: 20px;
}

.submitButton {
  display: flex;
  margin-top: 20px;

  & button {
    flex: 1;
  }
}
