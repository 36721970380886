// ButtonFilled1Primary - Background
$backgroundButtonFilled1PrimaryDefault: #E54F05;
$backgroundButtonFilled1PrimaryHovered: #FF8946;
$backgroundButtonFilled1PrimaryPressed: #DB4500;
$backgroundButtonFilled1PrimaryDisabled: #FBD1B9;

// ButtonFilled1Primary - Shadow
$shadowButtonFilled1PrimaryPressed: rgba(0, 0, 0, 0.17);

// ButtonFilled1Primary - Outline
$outlineButtonFilled1PrimaryFocused: #98BCD0;

// ButtonFilled1Primary - Text
$textButtonFilled1PrimaryDefault: #FFFFFF;

// ButtonFilled1Secondary - Background
$backgroundButtonFilled1SecondaryDefault: #F2F4F7;
$backgroundButtonFilled1SecondaryHovered: #E8EAEB;
$backgroundButtonFilled1SecondaryPressed: #F2F4F7;
$backgroundButtonFilled1SecondaryDisabled: #F2F4F7;

// ButtonFilled1Primary - Shadow
$shadowButtonFilled1SecondaryPressed: rgba(0, 0, 0, 0.07);

// ButtonFilled1Primary - Outline
$outlineButtonFilled1SecondaryFocused: #98BCD0;

// ButtonFilled1Secondary - Text
$textButtonFilled1SecondaryDefault: #E54F05;
$textButtonFilled1SecondaryDisabled: #FBD1B9;

// ButtonOutlined1Primary - Background
$backgroundButtonOutlined1PrimaryDefault: #FFFFFF;
$backgroundButtonOutlined1PrimarySelected: #E54F05;

// ButtonOutlined1Primary - Outline
$outlineButtonOutlined1PrimaryFocused: #98BCD0;

// ButtonOutlined1Primary - Stroke
$strokeButtonOutlined1PrimaryDefault: #E54F05;
$strokeButtonOutlined1PrimaryHovered: #FF8946;
$strokeButtonOutlined1PrimaryPressed: #DB4500;
$strokeButtonOutlined1PrimaryDisabled: #FBD1B9;

// ButtonOutlined1Primary - Text
$textButtonOutlined1PrimaryDefault: #E54F05;
$textButtonOutlined1PrimaryHovered: #FF8946;
$textButtonOutlined1PrimaryPressed: #DB4500;
$textButtonOutlined1PrimaryDisabled: #FBD1B9;

// ButtonFilled1Clear - Background
$backgroundButtonFilled1ClearDefault: #F7F9FC;

$shadowButtonFilled1ClearPressed: rgba(0, 0, 0, 0.17);

// Input1 - Stroke
$strokeButtonFilled1ClearDefault: rgba(0, 0, 0, 0.12);

// Input1 - Outline
$outlineButtonFilled1ClearFocused: #98BCD0;
