body, div {
  margin: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}
