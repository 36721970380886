@use "../../../../../../app/theme/palette/menus.module.scss" as *;
@use "../../../../../../app/theme/typography/fonts.module.scss" as *;

.mainScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100dvh;
}

.logo {
  width: 514px;
  height: 98px;
  margin: auto;
}

.link {
  $textSupportLink: #4F5A67;
  font-family: $fontFamily;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  color: $textSupportLink;
  text-align: right;
  margin-right: 34px;
  margin-bottom: 29px;

  a,
  a:visited,
  a:active {
    text-decoration: none;
    margin-left: 8px;
    color: inherit;
  }
}

.logoAndNameBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}