$backgroundCalendarItem1PrimarySelected: #E54F05;

$textCalendarItem1PrimaryDefault: #253247;
$textCalendarItem1PrimaryHovered: #FF8946;
$textCalendarItem1PrimarySelected: #FFFFFF;

$textCalendarItem2PrimaryDefault: #253247;
$textCalendarItem2PrimaryHovered: #FF8946;
$textCalendarItem2PrimarySelected: #FFFFFF;

$textCalendarItem1SecondaryDefault: #B8C1CC;
$textCalendarItem1SecondaryHovered: #FF8946;

$textCalendarItem2SecondaryDefault: #B8C1CC;
$textCalendarItem2SecondaryHovered: #FF8946;


