@use "../palette/dropdowns.module.scss" as *;
@use "../typography/dropdowns.module.scss" as *;

.dropdown {
  background-color: $backgroundDropdownPrimary;
  border-color: $strokeDropdownPrimary;
}

.dropdownItem1.primary {
  color: $textDropdownItem1PrimaryDefault;
}

.dropdownItem1.primary:hover {
  background-color: $backgroundDropdownItem1PrimaryHovered;
  color: $textDropdownItem1PrimaryHovered;
}

.dropdownItem1.primary.selected {
  color: $textDropdownItem1PrimarySelected;
}

.dropdownItem1.primary.disabled {
  color: $textDropdownItem1PrimaryDisabled;
}

.dropdownItem1.secondary {
  color: $textDropdownItem1SecondaryDefault;
}

.dropdownItem2.primary {
  color: $textDropdownItem1PrimaryDefault;
}

.dropdownItem2.primary:hover {
  background-color: $backgroundDropdownItem1PrimaryHovered;
  color: $textDropdownItem1PrimaryHovered;
}

.dropdownItem2.primary.selected {
  color: $textDropdownItem1PrimarySelected;
}

.dropdownItem2.primary.disabled {
  color: $textDropdownItem1PrimaryDisabled;
}
