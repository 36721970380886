.root {
  display: flex;
}

.segment {
  cursor: pointer;
  position: relative;
}

.segment.selected::after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -1px;
  z-index: 6;
}

.segmentText {
  display: block;
  padding: 8px 12px;
}
