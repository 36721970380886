.body {
  padding: 15px 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow: auto;

  & .field {
    width: 375px;
    flex: 0 0 auto;
  }
}

.footer {
  padding: 15px 20px;
  border-top: 1px solid #E0E3E7; // TODO: color from theme
  display: flex;
  flex-direction: row;
  gap: 16px;
}
