@use "../typography/headlines.module.scss" as *;
@use "../palette/headlines.module.scss" as *;

.headline1.primary {
  color: $headline1Primary;
}

.headline2.primary {
  color: $headline2Primary;
}

.headline3.primary {
  color: $headline3Primary;
}

.headline5.primary {
  color: $headline5Primary;
}

.headline6.primary {
  color: $headline6Primary;
}

.headline7.primary {
  color: $headline5Primary;
}
