$backgroundDropdownPrimary: #ffffff;

$strokeDropdownPrimary: #E54F05;

$backgroundDropdownItem1PrimaryHovered: #F7F9FC;

$textDropdownItem1PrimaryDefault: #253247;
$textDropdownItem1PrimaryHovered: #E54F05;
$textDropdownItem1PrimarySelected: #E54F05;
$textDropdownItem1PrimaryDisabled: #BAC0C5;

$textDropdownItem1SecondaryDefault: #BAC0C5;

$textDropdownItem2PrimaryDefault: #253247;
$textDropdownItem2PrimaryHovered: #E54F05;
$textDropdownItem2PrimarySelected: #E54F05;
$textDropdownItem2PrimaryDisabled: #BAC0C5;

