@use "../typography/buttons.module.scss" as *;
@use "../palette/buttons.module.scss" as *;

.buttonFilled1.primary {
  background-color: $backgroundButtonFilled1PrimaryDefault;
  color: $textButtonFilled1PrimaryDefault;
  outline: 2px solid transparent;
  border-radius: 5px;
  border: none;
}

.buttonFilled1.primary:hover {
  background-color: $backgroundButtonFilled1PrimaryHovered;
}

.buttonFilled1.primary:active {
  background-color: $backgroundButtonFilled1PrimaryPressed;
  box-shadow: 0 3px 5px 0 $shadowButtonFilled1PrimaryPressed inset;
}

.buttonFilled1.primary:focus {
  outline-color: $outlineButtonFilled1PrimaryFocused;
}

.buttonFilled1.primary:disabled,
.buttonFilled1.primary.disabled {
  background-color: $backgroundButtonFilled1PrimaryDisabled;
  box-shadow: none;
}

.buttonFilled1.secondary {
  background-color: $backgroundButtonFilled1SecondaryDefault;
  color: $textButtonFilled1SecondaryDefault;
  outline: 2px solid transparent;
  border-radius: 5px;
  border: none;
}

.buttonFilled1.secondary:hover {
  background-color: $backgroundButtonFilled1SecondaryHovered;
}

.buttonFilled1.secondary:active {
  background-color: $backgroundButtonFilled1SecondaryPressed;
  box-shadow: 0 3px 5px 0 $shadowButtonFilled1SecondaryPressed inset;
}

.buttonFilled1.secondary:focus {
  outline-color: $outlineButtonFilled1SecondaryFocused;
}

.buttonFilled1.secondary:disabled,
.buttonFilled1.secondary.disabled {
  background-color: $backgroundButtonFilled1SecondaryDisabled;
  color: $textButtonFilled1SecondaryDisabled;
  box-shadow: none;
}

.buttonOutlined1.primary {
  background-color: $backgroundButtonOutlined1PrimaryDefault;
  color: $textButtonOutlined1PrimaryDefault;
  outline: 2px solid transparent;
  border: 1px solid $strokeButtonOutlined1PrimaryDefault;
  border-radius: 5px;
}

.buttonOutlined1.primary:hover {
  color: $textButtonOutlined1PrimaryHovered;
  border-color: $strokeButtonOutlined1PrimaryHovered;
}

.buttonOutlined1.primary:active {
  box-shadow: none;
  color: $textButtonOutlined1PrimaryPressed;
  border-color: $strokeButtonOutlined1PrimaryPressed;
}

.buttonOutlined1.primary:focus {
  outline-color: $outlineButtonOutlined1PrimaryFocused;
}

.buttonOutlined1.primary:disabled,
.buttonOutlined1.primary.disabled {
  color: $textButtonOutlined1PrimaryDisabled;
  border-color: $strokeButtonOutlined1PrimaryDisabled;
  box-shadow: none;
}

.buttonOutlined1.primary.selected {
  background-color: $backgroundButtonOutlined1PrimarySelected;
}

.buttonOutlined1.primary.selected {
  background-color: $backgroundButtonOutlined1PrimarySelected;
}

.filterIcon {
  display: flex;
  width: 24px;
  height: 24px;
}

.buttonOutlined1.primary .filterIcon {
  background-image: url("../../resources/assets/icons/icFilterDefault.svg");
}

.buttonOutlined1.primary:hover .filterIcon,
.buttonOutlined1.primary.selected:hover .filterIcon {
  background-image: url("../../resources/assets/icons/icFilterHovered.svg");
}

.buttonOutlined1.primary:active .filterIcon,
.buttonOutlined1.primary.selected:active .filterIcon {
  background-image: url("../../resources/assets/icons/icFilterClicked.svg");
}

.buttonOutlined1.primary:disabled .filterIcon,
.buttonOutlined1.primary.disabled .filterIcon {
  background-image: url("../../resources/assets/icons/icFilterDisabled.svg");
}

.filterIcon {
  background-image: url("../../resources/assets/icons/icFilterActive.svg");
}

.buttonOutlined1.primary.selected .filterIcon {
  background-image: url("../../resources/assets/icons/icFilterActive.svg");
}

.buttonFilled1.clear {
  border: 1px solid $strokeButtonFilled1ClearDefault;
  outline: 2px solid transparent;
  border-radius: 5px;
  background-color: $backgroundButtonFilled1ClearDefault;
}

.buttonFilled1.clear:focus,
.buttonFilled1.clear:focus-visible {
  outline-color: $outlineButtonFilled1ClearFocused;
}

.buttonFilled1.clear:active {
  box-shadow: 0 3px 5px 0 $shadowButtonFilled1ClearPressed inset;
}

.clearIcon {
  display: flex;
  width: 24px;
  height: 24px;
  background-image: url("../../resources/assets/icons/icClearRoundedDefault.svg");
}

.buttonFilled1.clear:hover .clearIcon {
  background-image: url("../../resources/assets/icons/icClearRoundedHovered.svg");
}

.buttonFilled1.clear:active .clearIcon {
  background-image: url("../../resources/assets/icons/icClearRoundedClicked.svg");
}

.buttonFilled1.clear:disabled .clearIcon {
  background-image: url("../../resources/assets/icons/icClearRoundedDisabled.svg");
}
