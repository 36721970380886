.root {
  padding: 10px 0;
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: rotation 1s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
