.root {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
  justify-content: center;
}

.rightAlignment {
  align-items: flex-end;
}

.link {
  position: relative;
  z-index: 2;
}
