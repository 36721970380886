@use '../../../../../core/presentation/constants/dimens' as *;

$tableMargin: 20px;

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  margin: $tableMargin;
  flex: 1 1 auto;

  & th,
  & td {
    & .rowLink {
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      z-index: 1;
    }

    & .cellLink {
      position: relative;
      z-index: 2;
    }
  }
}

.table th,
.table td {
  position: relative;
  text-align: left;
  vertical-align: top;
}

.table th {
  vertical-align: middle;
}

.table td {
  vertical-align: top;
}

.table thead th {
  pointer-events: none;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
}

.table thead th:first-child {
  border-top-left-radius: 4px;
  border-left: 1px solid transparent;
}

.table thead th:last-child {
  border-top-right-radius: 4px;
  border-right: 1px solid transparent;
}

.thContent {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.thContent.rightAlignment, {
  justify-content: flex-end;
}

.tdContent {
  padding: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.tdContent.rightAlignment {
  justify-content: flex-end;
}

.clickable {
  cursor: pointer;
  pointer-events: all !important;
}

.table tr td {
  border-bottom: 1px solid transparent;
}

.table tbody tr td {
  border-right: 1px dashed transparent;
}

.table tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.table tr:last-child td:last-child {
  border-bottom-right-radius: 4px;
}

.table tr td:first-child {
  border-left: 1px solid transparent;
}

.table tr td:last-child {
  border-right: 1px solid transparent;
}

.table tfoot td {
  border: none !important;
}

.table tfoot tr:hover td {
  background: transparent !important;
}

.loadingIndicatorRow:hover td,
.errorMessageRow:hover td,
.emptyStateRow:hover td {
  background: transparent !important;
}

.messageContainer {
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
}

.descriptionContainer {
  padding: 0 $tableMargin $tableMargin;
}


