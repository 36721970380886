.root {
  display: flex;
  width: 100%;
  flex-direction: column;

  & .selectButtonContainer {
    flex: 1 1 auto;

    & .selectContainer {
      position: relative;
    }

    & .selectContainer.disabled {
      pointer-events: none;
    }

    & .icon {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 4px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }

    & .selectButton {
      width: 100%;
      cursor: pointer;
      padding: 12px 8px;
    }
  }

  //TODO: Changed by design
  & .clearButton {
    flex: 0 0 auto;
    margin-left: 8px;
  }
}

.valuesContainer {
  display: flex;
  flex-direction: row;
  gap: 8px;
  flex-wrap: wrap;
  margin: 10px 0;
}

.dropdown {
  border-radius: 5px;
  border: 1px solid transparent;
  //margin: 5px 0;
}

.dropdownContent {
  width: 100%;
  padding: 8px 0;
}

.dropdownOption {
  cursor: pointer;
  padding: 8px 10px;
  width: 100%;
}
