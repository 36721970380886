$rootPageLeftMenuWidth: 220px;
$rootPageLeftMenuHeaderHeight: 80px;

$objectPageVerticalPadding: 12px;
$objectPageHorizontalPadding: 20px;

$objectPageFormHorizontalPadding: 20px;
$objectPageFormWidth: 50%;
$objectPageHeaderHeight: 58px;
$objectPageFooterHeight: 80px;

$objectPageContentWidth: calc(100% - $rootPageLeftMenuWidth - $objectPageHorizontalPadding * 2);

