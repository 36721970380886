.root {
  display: flex;
  gap: 8px;

  & .selectButtonContainer {
    flex: 1 1 auto;

    & .selectContainer {
      position: relative;
    }

    & .selectContainer.disabled {
      pointer-events: none;
    }

    & .icon {
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 4px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }
  }
}

.selectButton {
  width: 100%;
  cursor: pointer;
  padding: 12px 8px;
}

.dropdownContent {
  width: 100%;
  padding: 8px 0;
}

.dropdownOption {
  cursor: pointer;
  padding: 8px 10px;
  width: 100%;
}
