.root {
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;
}

.checkbox {
  display: none;
}

.labelContainer {
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  justify-content: center;
  user-select: none;
}

.label {
  display: block;
}

.additionalLabel {
  display: block;
}
