.root {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.changeDateContainer {
  width: 100%;
  display: flex;
}

.inputContainer {
  width: 100%;
  position: relative;
  flex-direction: row;
  display: flex;
  gap: 20px;
}

.inputWrapper {
  width: 100%;
}

.input {
  width: 100%;
}

.date {
  cursor: pointer;
}

.calendarIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding-right: 4px;
}

.calendarIcon {
  width: 24px;
  height: 24px;
}

.clearButton {
  margin-left: 8px;
}

.dropdown {
  border-radius: 5px;
  border: 1px solid transparent;
  padding: 10px 8px;
}

.dropdownContent {
  width: 100%;
  padding: 8px 0;
}
