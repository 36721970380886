@use '../../../../../core/presentation/constants/dimens' as *;

.root {
  width: $objectPageFormWidth;
  padding: 15px $objectPageFormHorizontalPadding;
}

.field {
  margin-bottom: 15px;
}

.fieldsGroupTitle {
  margin-top: 0;
  margin-bottom: 15px;
  display: block;
}

.fieldsGroupTitleWithoutMargins {
  margin-top: 0;
  margin-bottom: 0;
  display: block;
}

.listObjectWrapper {
  display: flex;
  flex-direction: column;
}

.listObjectContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 15px;
}

.listObjectActions {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.listObjectTitle {
  margin-top: 0;
  margin-bottom: 10px;
  display: block;
}

.listObjectFields {
  width: 100%;
  padding: 0;
}

.listError {
  margin-bottom: 5px;
}


