@use "../palette/objects.module.scss" as *;

.object {
  background-color: $backgroundObject;
}

.objectHeader {
  background-color: $backgroundObjectHeader;
  border-color: $strokeObjectHeader;
}

.objectContent {
  background-color: $backgroundObjectContent;
  border-color: $strokeObjectContent;
}

.objectFooter {
  background-color: $backgroundObjectFooter;
  border-color: $strokeObjectFooter;
}


