.root {
  width: 100%;
  padding: 12px 15px;
}

.title {
  margin-bottom: 4px;
}

.text {
  white-space: pre-wrap;
}
