.root {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  gap: 2px;
}

.links {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  gap: 2px;
}
