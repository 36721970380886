@use "../typography/menus.module.scss" as *;
@use "../palette/menus.module.scss" as *;

.menu1.primary {
  color: $textMenu1PrimaryDefault;
  text-decoration: none;
}

.menu1.primary:hover {
  color: $textMenu1PrimaryHovered;
}

.menu1.primary:hover svg path {
  fill: $textMenu1PrimaryHovered;
}

.menu1.primary.active {
  color: $textMenu1PrimaryActive;
  background-color: $backgroundMenu1PrimaryActive;
}

.menu1.primary.active svg path {
  fill: $textMenu1PrimaryActive;
}
