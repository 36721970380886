$contentWidth: 443px;

.root {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  width: $contentWidth;
  align-items: center;
  justify-content: center;
}

.title {
  margin-top: 0;
  margin-bottom: 15px;
}

.text {
  text-align: center;
  margin-bottom: 20px;
}

.button {
  padding: 10px 28px;
  min-width: 177px;
}
