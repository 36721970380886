// Input1 - Background
$backgroundInput1PrimaryDefault: #F7F9FC;
$backgroundInput1PrimaryDisabled: #EBEEF1;

// Input1 - Stroke
$strokeInput1PrimaryDefault: rgba(0, 0, 0, 0.12);
$strokeInput1PrimaryHovered: #E54F05;
$strokeInput1PrimaryPressed: #E54F05;
$strokeInput1PrimaryDisabled: rgba(0, 0, 0, 0.12);
$strokeInput1PrimaryError: #E64646;

// Input1 - Outline
$outlineInput1PrimaryFocused: #98BCD0;

// Input1 - Text
$textInput1PrimaryDefault: #253247;

// Input1 - Placeholder
$placeholderInput1PrimaryDefault: #9FAAB7;
$placeholderInput1PrimaryDisabled: #6D7885;

// Input2 - Background
$backgroundInput2PrimaryDefault: #F7F9FC;
$backgroundInput2PrimaryDisabled: #EBEEF1;

// Input2 - Stroke
$strokeInput2PrimaryDefault: rgba(0, 0, 0, 0.12);
$strokeInput2PrimaryHovered: #E54F05;
$strokeInput2PrimaryPressed: #E54F05;
$strokeInput2PrimaryDisabled: rgba(0, 0, 0, 0.12);
$strokeInput2PrimaryError: #E64646;

// Input1 - Outline
$outlineInput2PrimaryFocused: #98BCD0;

// Input1 - Text
$textInput2PrimaryDefault: #253247;

// Input1 - Placeholder
$placeholderInput2PrimaryDefault: #9FAAB7;
$placeholderInput2PrimaryDisabled: #6D7885;
