@use '../../../../../core/presentation/constants/dimens' as *;

.root {
  display: flex;
  flex-direction: row;
  min-height: 100%;
}

.infoRowsContainer {
  display: block;
  padding: 15px $objectPageFormHorizontalPadding;
  min-width: 45%;
}

.infoRowsTable {
  width: 100%;
  height: auto;
}

.infoRowsTable tr td:first-child {
  width: 1%;
  white-space: nowrap;
  padding-right: 27px;
}

.infoRowsTable td {
  padding-bottom: 12px;
  vertical-align: top;
}

.linksContainer {
  padding: 15px $objectPageFormHorizontalPadding;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-left: 1px solid red;
}

.link {
  display: block;
}
